@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";
@import "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap";

:root {
  --filter-color: #c3dff4;
  --main-bg-color: #f2f8fd;
  --icon-color: #2980b9;
  --hover-color: #e4effa;
}

.linear-home {
  background: linear-gradient(115deg, #06002b 0%, #12226a 67%, #0f1b38 100%);
}

.dashboard-card {
  border-radius: 8px;
  padding: 24px;
  background-color: white;
  flex-direction: column;
}

.styled-input {
  height: 40px;
  font-family: "Inter", sans-serif;
  padding: 10px !important;
  border: 1px solid #cbd5e0; /* Adjust border color */
  border-radius: 4px !important;
  outline: none;
  font-size: 16px;
}

.styled-input:focus {
  border-color: #007fbf; /* Adjust focus border color */
  border-width: 2px;
}

.loading {
  background: linear-gradient(-90deg, #ffffff, #bebebe);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.spinner {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
